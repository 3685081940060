// src/Components/Create/Species.jsx
import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalHeader, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { FaTimes } from "react-icons/fa";
import * as Yup from 'yup';
import './Create.css'; // Ensure the path is correct
import { MdModeEdit } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux';
import { setSpecies } from '../../actions'; // Correct path to actions

const Species = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    // Retrieve species from Redux store
    const species = useSelector((state) => state.species);
    const [errorMessage, setErrorMessage] = useState(false)
    const [isEditing, setIsEditing] = useState(false);
    const [currentEditIndex, setCurrentEditIndex] = useState(null);


    console.log(species.length, "lenght")

    const [speciesModal, setSpeciesModal] = useState(false);

    // const schema = Yup.object().shape({
    //     speciesName: Yup.string().required('Species Name is required'),
    //     speciesPoints: Yup.number()
    //         .typeError('Species Points must be a number')
    //         .required('Species points are required'),
    //     daily_limit: Yup.number()
    //         .typeError('Daily limit must be a number')
    //         .test('is-less-than-tournament', 'Daily limit must be less than tournament limit', function (value) {
    //             const { tournament_limit } = this.parent; // Get the value of tournament_limit
    //             return value < tournament_limit || tournament_limit === undefined;
    //         }),
    //     tournament_limit: Yup.number()
    //         .typeError('Tournament limit must be a number')
    //         .test('is-greater-than-daily', 'Tournament limit must be greater than daily limit', function (value) {
    //             const { daily_limit } = this.parent; // Get the value of daily_limit
    //             return value > daily_limit || daily_limit === undefined;
    //         }),
    //     min_weight: Yup.number().typeError('Min weight must be a number'),
    //     weight_unit: Yup.string(),
    //     min_length: Yup.number().typeError('Min length must be a number'),
    //     length_unit: Yup.string(),
    // });

    const schema = Yup.object().shape({
        speciesName: Yup.string().required('Species Name is required'),
        speciesPoints: Yup.number()
            .typeError('Species Points must be a number')
            .required('Species points are required'),
        daily_limit: Yup.number()
            .typeError('Daily limit must be a number')
            .nullable() // Allow it to be null if not entered
            .test('is-less-than-tournament', 'Daily limit must be less than tournament limit', function (value) {
                const { tournament_limit } = this.parent;
                return value === null || value === undefined || value < tournament_limit || tournament_limit === undefined;
            }),
        tournament_limit: Yup.number()
            .typeError('Tournament limit must be a number')
            .nullable() // Allow it to be null if not entered
            .test('is-greater-than-daily', 'Tournament limit must be greater than daily limit', function (value) {
                const { daily_limit } = this.parent;
                return value === null || value === undefined || value > daily_limit || daily_limit === undefined;
            }),
        min_weight: Yup.number().typeError('Min weight must be a number'),
        weight_unit: Yup.string(),
        min_length: Yup.number().typeError('Min length must be a number'),
        length_unit: Yup.string(),
    });

    const handleAddSpecies = (values) => {
        // Append the new species to the existing species array
        const updatedSpecies = [...species, values];
        console.log("Dispatching Species Data:", updatedSpecies);
        dispatch(setSpecies(updatedSpecies));
        setErrorMessage(false)
    };

    const handleDeleteRow = (index) => {
        const updatedSpecies = species.filter((_, i) => i !== index);
        console.log("Dispatching Species Data after deletion:", updatedSpecies);
        dispatch(setSpecies(updatedSpecies));
    };

    const handleEditRow = (index) => {
        setIsEditing(true);
        setCurrentEditIndex(index);
        setSpeciesModal(true);
    };

    const initialFormValues = isEditing && currentEditIndex !== null && species[currentEditIndex]
        ? {
            speciesName: species[currentEditIndex].speciesName || "",
            speciesPoints: species[currentEditIndex].speciesPoints || "",
            min_weight: species[currentEditIndex].min_weight || "",
            weight_unit: species[currentEditIndex].weight_unit || "",
            min_length: species[currentEditIndex].min_length || "",
            length_unit: species[currentEditIndex].length_unit || "",
            daily_limit: species[currentEditIndex].daily_limit || "",
            tournament_limit: species[currentEditIndex].tournament_limit || ""
        }
        : {
            speciesName: "",
            speciesPoints: "",
            min_weight: "",
            weight_unit: "",
            min_length: "",
            length_unit: "",
            daily_limit: "",
            tournament_limit: ""
        };

    const handleUpdateSpecies = (index, values) => {
        const updatedSpecies = species.map((spec, idx) => idx === index ? values : spec);
        console.log("Dispatching Updated Species Data:", updatedSpecies);
        dispatch(setSpecies(updatedSpecies));
        setErrorMessage(false);
    };


    return (
        <>
            <div>
                <div className='species-button-group'>
                    <div className='page-title'>Species</div>
                    <Button
                        type="button"
                        onClick={() => setSpeciesModal(true)}
                        className="add-species-button"
                    >
                        Add species
                    </Button>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Species Name</th>
                            <th>Points</th>
                            <th>Min Weight</th>
                            <th>Min Length</th>
                            <th>Daily Limit</th>
                            <th>Tournament Limit</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {species && species.length > 0 ? (
                            species.map((spec, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{spec.speciesName}</td>
                                    <td>{spec.speciesPoints}</td>
                                    <td>{spec.min_weight || "-"} {spec.weight_unit}</td>
                                    <td>{spec.min_length || "-"} {spec.length_unit}</td>
                                    <td>{spec.daily_limit || "-"}</td>
                                    <td>{spec.tournament_limit || "-"}</td>
                                    <td>
                                        <Button onClick={() => handleEditRow(index)} style={{ cursor: 'pointer' }} className='edit-button' >
                                            Edit
                                        </Button>
                                        <Button onClick={() => handleDeleteRow(index)} style={{ cursor: 'pointer' }} className='delete-button' >
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="10">No species available</td>
                            </tr>
                        )}
                    </tbody>
                </Table>

                {errorMessage &&
                    <p className='species-error'>
                        Please add atleast one species
                    </p>}

                <div className="species-button-group">
                    <Button
                        type="button"
                        className="next-create-button"
                        onClick={() => {
                            // Update 'page' in localStorage
                            localStorage.setItem("page", "/user-login/create/setup");
                            navigate('/user-login/create/setup');
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        type="button"
                        className="next-create-button"
                        onClick={() => {
                            // Update 'page' in localStorage
                            if (species.length > 0) {
                                localStorage.setItem("page", "teams");
                                navigate('/user-login/create/teams');
                            } else {
                                setErrorMessage(true)
                            }
                        }}
                    >
                        Next
                    </Button>
                </div>
            </div>

            <Modal
                show={speciesModal}
                onHide={() => setSpeciesModal(false)}
                backdrop="static"
                centered
            >
                {/* <ModalHeader closeButton>Add Species</ModalHeader> */}
                <ModalHeader closeButton>{isEditing ? "Edit Species" : "Add Species"}</ModalHeader>

                <ModalBody>
                    <Formik
                        validationSchema={schema}
                        // initialValues={{
                        //     speciesName: "",
                        //     speciesPoints: "",
                        //     min_weight: "",
                        //     weight_unit: "",
                        //     min_length: "",
                        //     length_unit: "",
                        //     daily_limit: "",
                        //     tournament_limit: ""
                        // }}
                        initialValues={initialFormValues}
                        // onSubmit={(values, { setSubmitting, resetForm }) => {
                        //     setSubmitting(false);
                        //     handleAddSpecies(values);
                        //     setSpeciesModal(false);
                        //     resetForm();
                        // }}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(false);
                            if (isEditing && currentEditIndex !== null) {
                                handleUpdateSpecies(currentEditIndex, values);
                            } else {
                                handleAddSpecies(values);
                            }
                            setSpeciesModal(false);
                            setIsEditing(false);
                            setCurrentEditIndex(null);
                            resetForm();
                        }}

                    >
                        {({ setFieldValue }) => (
                            <Form className="mb-3">
                                <div className='species-field'>
                                    <div className="species-name mb-3">
                                        <label htmlFor="speciesName">Species Name<span style={{ color: 'red' }}>*</span></label>
                                        <Field
                                            type="text"
                                            id="speciesName"
                                            name="speciesName"
                                            autoComplete="off"
                                        />
                                        <div className="error-wrapper mb-3">
                                            <ErrorMessage name="speciesName" component="div" className="error-message" />
                                        </div>
                                    </div>

                                    <div className="species-points mb-3">
                                        <label htmlFor="speciesPoints">Points<span style={{ color: 'red' }}>*</span></label>
                                        <Field
                                            type="number"
                                            id="speciesPoints"
                                            name="speciesPoints"
                                            autoComplete="off"
                                            onWheel={(e) => e.currentTarget.blur()} // Disable scroll
                                        />
                                        <div className="error-wrapper mb-3">
                                            <ErrorMessage name="speciesPoints" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>

                                <div className='details-half'>
                                    <div className="details-field mb-3">
                                        <label htmlFor="min_weight">Min Weight</label>
                                        <Field
                                            type="number"
                                            id="min_weight"
                                            name="min_weight"
                                            autoComplete="off"
                                            onWheel={(e) => e.currentTarget.blur()} // Disable scroll
                                        />
                                        <div className="error-wrapper mb-3">
                                            <ErrorMessage name="min_weight" component="div" className="error-message" />
                                        </div>
                                    </div>

                                    <div className="details-field mb-3">
                                        <label htmlFor="min_length">Min Length</label>
                                        <Field
                                            type="number"
                                            id="min_length"
                                            name="min_length"
                                            autoComplete="off"
                                            onWheel={(e) => e.currentTarget.blur()} // Disable scroll
                                        />
                                        <div className="error-wrapper mb-3">
                                            <ErrorMessage name="min_length" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>

                                <div className='details-half'>
                                    <div className="details-field mb-3">
                                        <label htmlFor="daily_limit">Daily Limit</label>
                                        <Field
                                            type="number"
                                            id="daily_limit"
                                            name="daily_limit"
                                            autoComplete="off"
                                            onWheel={(e) => e.currentTarget.blur()} // Disable scroll
                                        />
                                        <div className="error-wrapper mb-3">
                                            <ErrorMessage name="daily_limit" component="div" className="error-message" />
                                        </div>
                                    </div>

                                    <div className="details-field mb-3">
                                        <label htmlFor="tournament_limit">Tournament Limit</label>
                                        <Field
                                            type="number"
                                            id="tournament_limit"
                                            name="tournament_limit"
                                            autoComplete="off"
                                            onWheel={(e) => e.currentTarget.blur()} // Disable scroll
                                        />
                                        <div className="error-wrapper mb-3">
                                            <ErrorMessage name="tournament_limit" component="div" className="error-message" />
                                        </div>
                                    </div>
                                </div>

                                {/* <Button type="submit" className="next-create-button">
                                    Submit
                                </Button> */}

                                <Button type="submit" className="next-create-button">
                                    {isEditing ? "Update" : "Submit"}
                                </Button>

                            </Form>
                        )}
                    </Formik>
                </ModalBody>
            </Modal>
        </>
    )
};

export default Species;